import React from 'react'
import { Route, Routes, Link, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl, defineMessage } from 'react-intl'
import useBreakpoint from '../state/useBreakpoint'
import useConfig from '../state/useConfig'
import useUser from '../state/useUser'
import { Errors, PlatformNavigationError } from '../utils/error'
import { useRuntime } from '../state/useRuntime'
import useApi from '../api/api'
import {
  _redirectToSupportCenter,
  _redirectToSupportCenterPage
} from '../api/redirectToSupportCenter'
import domains from '../utils/domains'
import { removeCompanyCookie, removeImpersonationCookie } from '../utils/cookie'

export const ApplicationRoutes = ({ routes }) => {
  validateRoutes(routes)
  const api = useApi()
  const intl = useIntl()
  const config = useConfig()
  const breakpoint = useBreakpoint()
  const [user, setCompany] = useUser()
  const { PlatformNav, Error404, SupportCenterRedirect, OauthCallback, Logout, useGlobalLogout } =
    useRuntime()

  useGlobalLogout()

  return (
    <Routes>
      {routes.map(
        ({
          path,
          titleMessage,
          Content,
          Dimensions,
          Filters,
          Buttons,
          sideNavLinkGroups,
          legacyPage,
          defaultShowFilterPanel,
          toggleRightPanelIcon,
          rightPanelIconVisible,
          onBackButtonClick
        }) => {
          return (
            <Route
              key={path}
              path={path}
              element={
                <PlatformNav
                  rightPanelIconVisible={rightPanelIconVisible}
                  Content={Content}
                  layout={layoutOf(Filters, sideNavLinkGroups, Buttons, Dimensions)}
                  titleMessage={titleMessage}
                  Dimensions={Dimensions}
                  Filters={Filters}
                  sideNavLinkGroups={sideNavLinkGroups}
                  Buttons={Buttons}
                  legacyPage={legacyPage}
                  defaultShowFilterPanel={defaultShowFilterPanel}
                  location={location}
                  api={api}
                  Link={Link}
                  intl={intl}
                  user={user}
                  setCompany={setCompany}
                  FormattedMessage={FormattedMessage}
                  config={config}
                  breakpoint={breakpoint}
                  defineMessage={defineMessage}
                  useNavigate={useNavigate}
                  toggleRightPanelIcon={toggleRightPanelIcon}
                  onBackButtonClick={onBackButtonClick}
                  domains={domains}
                />
              }
            />
          )
        }
      )}
      <Route path="/oauth-callback" element={<OauthCallback intl={intl} />} />
      <Route
        path="/logout"
        element={
          <Logout
            api={api}
            intl={intl}
            removeCompanyCookie={removeCompanyCookie}
            removeImpersonationCookie={removeImpersonationCookie}
          />
        }
      />
      <Route
        path="/support-center"
        element={
          <SupportCenterRedirect
            user={user}
            api={api}
            intl={intl}
            useNavigate={useNavigate}
            FormattedMessage={FormattedMessage}
            _redirectToSupportCenter={_redirectToSupportCenter}
            _redirectToSupportCenterPage={_redirectToSupportCenterPage}
          />
        }
      />
      <Route path="*" element={<Error404 intl={intl} />} />
    </Routes>
  )
}
function layoutOf(Filters, sideNavLinkGroups, Buttons, Dimensions) {
  return {
    filters: typeof Filters !== 'undefined' && Filters !== null,
    sidenav: typeof sideNavLinkGroups !== 'undefined' && sideNavLinkGroups !== null,
    buttons: typeof Buttons !== 'undefined' && Buttons !== null,
    dimensions: typeof Dimensions !== 'undefined' && Dimensions !== null
  }
}
function validateRoutes(routes) {
  const errors = routes.reduce((errors, route, idx) => errors.concat(validateRoute(route, idx)), [])
  if (errors.length > 0) throw new Errors(errors)
}
function validateRoute({ path, titleMessage, Content }, idx) {
  const errors = []

  if (!path) errors.push(new PlatformNavigationError(`No path provided for route at index: ${idx}`))
  if (!titleMessage)
    errors.push(new PlatformNavigationError(`No titleMessage provided for path: ${path}`))
  if (!Content)
    errors.push(new PlatformNavigationError(`No Content component provided for path: ${path}`))

  return errors
}
