import React, { createContext, useContext } from 'react'
import domainsByEnvByApp from '../utils/domains'
import { Errors, PlatformNavigationError } from '../utils/error'

export default function useConfig() {
  return useContext(ConfigContext)
}

export const ConfigContext = createContext({})

export const ConfigContextProvider = ({ children, config = {}, domains }) => {
  const value = {
    ...config,
    platformDomains: buildPlatformDomains(domains)
  }
  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
}

export const _buildPlatformDomains = (location) => (domains) =>
  domains ? _buildDevDomains(location)(domains) : buildProdDomains()

const buildPlatformDomains = _buildPlatformDomains(window.location)

const _buildDevDomains = (location) => (domains) =>
  Object.entries(validateDomains(domains)).reduce((config, [app, env]) => {
    const domainsByEnv = domainsByEnvByApp[app]
    config[app] = env === 'local' ? location.origin : domainsByEnv[env]
    return config
  }, emptyConfig())

const buildProdDomains = () =>
  Object.entries(domainsByEnvByApp).reduce((config, [app, domainsByEnv]) => {
    config[app] = domainsByEnv.prod
    return config
  }, {})

const emptyConfig = () =>
  Object.keys(domainsByEnvByApp).reduce((config, app) => {
    config[app] = ''
    return config
  }, {})

const validateDomains = (domains) => {
  const errors = Object.entries(domains).reduce((errors, [app, env]) => {
    const message = validateDomainsEntry(app, env)
    if (message) errors.push(new PlatformNavigationError(message))
    return errors
  }, [])

  if (errors.length > 0) throw new Errors(errors)

  return domains
}

const validateDomainsEntry = (app, env) =>
  validations.reduce((message, validate) => message || validate(app, env), '')

const validateApp = (app) => {
  if (!domainsByEnvByApp.hasOwnProperty(app)) {
    return `'${app}' is not a valid key in \`platformDomains\`! Please use one of the following: ${Object.keys(
      domainsByEnvByApp
    )}`
  }
}

const validateEnv = (app, env) => {
  const domainsByEnv = domainsByEnvByApp[app]
  const domain = env === 'local' ? location.origin : domainsByEnv[env]
  if (!domain) {
    return `'${env}' is not a valid value for key '${app}' in \`platformDomains\`! Please use one of the following: ${[
      'local',
      ...Object.keys(domainsByEnv)
    ]}`
  }
}

const validations = [validateApp, validateEnv]
