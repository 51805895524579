import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { initAuth } from '@platform/platform-auth'
import { BreakpointContextProvider } from '../state/useBreakpoint'
import { ConfigContextProvider } from '../state/useConfig'
import { UserContextProvider } from '../state/useUser'
import { CompanyContextProvider } from './CompanyContextProvider'
import ReactIntlProvider from './ReactIntlProvider'
import { Loading } from '../Components/Loading'
import { RuntimeContextProvider } from '../state/useRuntime'
import { ApplicationRoutes } from './ApplicationRoutes'

export const _PlatformApp =
  (config) =>
  ({ routes, translations, platformDomains }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading, setLoading] = useState(true)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const isMemberApp = window.location.pathname.match(/\/member\/*/)

      /*
          Because member is a collection of applications served under various route structures,
          and we stored the bearer token in the applications memory, we cannot add an oauth 
          callback route to the app in a consistent way, nor can we add a generic member oauth
          callback route. Instead, we can rely on the bearer token member has already set in the
          cookie `authentication_token`. To have the auth library treat that cookie as the source
          of truth, we can simply not call `initAuth` when on a member page.
          */
      if (isMemberApp) {
        setLoading(false)
      } else {
        const redirectUri = `${window.location.origin}/oauth-callback`

        initAuth({
          clientId: window.location.host.match(/member102.lab.cj.com/)
            ? 'ZUxBmByWlGV0SLxWPkRKzInn41OlCnSp'
            : 'PNSvFDQzkiyy1A668z5ZsxXGEgRGN88h',
          redirectUri
        }).then(() => setLoading(false))
      }
    }, [])

    return loading ? (
      <Loading />
    ) : (
      <ConfigContextProvider config={config} domains={platformDomains}>
        <UserContextProvider>
          <ReactIntlProvider translations={translations}>
            <RuntimeContextProvider>
              <BreakpointContextProvider>
                <CompanyContextProvider>
                  <BrowserRouter>
                    <ApplicationRoutes routes={routes} />
                  </BrowserRouter>
                </CompanyContextProvider>
              </BreakpointContextProvider>
            </RuntimeContextProvider>
          </ReactIntlProvider>
        </UserContextProvider>
      </ConfigContextProvider>
    )
  }

export const PlatformApp = _PlatformApp({ staticAssetsPath: 'https://platform.cj.com/nav/static' })
